<template>
	<div class="home">
		<div class="left">
			<div class="up">
				<div class="ring">
					<div ref="lu1"></div>
				</div>
				<div class="ring">
					<div ref="lu2"></div>
				</div>
				<div class="ring">
					<div ref="lu3"></div>
				</div>
				<div class="ring">
					<div ref="lu4"></div>
				</div>
			</div>
			<div class="middle">
				<div class="title">房屋状态</div>
				<div class="content">
					<div v-for="item in getHouseState" :key="item.name">
						<span class="label">{{ item.name }}</span>
						<span class="value">{{ (item.percent * 100).toFixed(2) }}%</span>
					</div>
				</div>
			</div>
			<div class="down">
				<div class="title">房屋类型</div>
				<div ref="ld1" class="category"></div>
			</div>
		</div>
		<div class="center">
			<div class="up">
				<div>
					<!-- <div class="value">{{ totalStatistics.houseNum || 0 }}</div> -->
					<div class="value"><rollNum :num="totalStatistics.houseNum" /></div>
					<div class="label">房屋总数</div>
				</div>
				<div>
					<!-- <div class="value">{{ totalStatistics.peopleNum || 0 }}</div> -->
					<div class="value"><rollNum :num="totalStatistics.personnelNum" /></div>
					<div class="label">人口总数</div>
				</div>
				<div>
					<!-- <div class="value">{{ totalStatistics.faceNum || 0 }}</div> -->
					<div class="value"><rollNum :num="totalStatistics.lockNum" /></div>
					<div class="label">门禁总数</div>
				</div>
				<div>
					<!-- <div class="value">{{ totalStatistics.personnelNum || 0 }}</div> -->
					<div class="value"><rollNum :num="totalStatistics.unlockNum" /></div>
					<div class="label">开门记录</div>
				</div>
			</div>
			<div class="middle">
				<div ref="map"></div>
			</div>
			<div class="down">
				<div class="title">户籍地分布</div>
				<div ref="md1" class="category"></div>
			</div>
		</div>
		<div class="right">
			<div class="up">
				<div class="title">当日进出峰值</div>
				<div ref="ru" class="category"></div>
			</div>
			<div class="middle">
				<div class="title">近30天开门方式</div>
				<div ref="rm" class="category"></div>
			</div>
			<div class="down">
				<div class="title">门禁在线</div>
				<div ref="rd" class="category"></div>
			</div>
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts'
// import homeTab from '../components/homeTab'
// import svgIcon from '@/components/svgIcon.vue'
import rollNum from '@/components/rollNum.vue'
export default {
	name: 'Home',
	components: {
		// homeTab,
		// svgIcon
		rollNum
	},
	data() {
		return {
			openDoorModeData: [],
			peakValueOfDayData: [],
			accessControlOnlineRate: '',
			province: '',
			parentCode: '',
			parentName: '',
			zoom: 1.4,
			parentList: [],
			backMap: null,
			mapJson: [],
			mapMax: '',
			mapValueData: [],
			totalStatistics: {},
			getTypePercent: {},
			getHouseState: [],
			getHouseType: {},
			color: '#fff'
		}
	},
	computed: {
		computedTheme() {
			return this.$store.state.theme
		}
	},
	watch: {
		computedTheme: function(old) {
			this.setcolor(old)
			this.init()
		}
	},
	mounted() {
		let old = localStorage.getItem('theme')
		this.setcolor(old)
		this.init()
	},
	//页面销毁后移除监听
	beforeDestroy() {
		window.removeEventListener('resize', this.resize)
	},
	methods: {
		setcolor(old) {
			if (old === '') {
				this.color = '#fff'
			} else if (old == 0) {
				this.color = '#333'
			}
		},
		init() {
			var colors = ['#FFBB32', '#C057FD', '#2BD03B', '#52C2FF', '#FD5F57']
			this.ajax.get('admin/index/getTypePercent').then(res => {
				this.getTypePercent = res
				var list1 = res[0]?.list || []
				var l_u_option1 = this.getLeftUpPie('age', '年龄占比', list1, colors)
				var myChart1 = echarts.init(this.$refs.lu1)
				myChart1.setOption(l_u_option1)

				var list2 = res[1]?.list || []
				var l_u_option2 = this.getLeftUpPie('sex', '性别占比', list2, colors)
				var myChart2 = echarts.init(this.$refs.lu2)
				myChart2.setOption(l_u_option2)

				var list3 = res[2]?.list || []
				var l_u_option3 = this.getLeftUpPie('perType', '人口类型', list3, colors)
				var myChart3 = echarts.init(this.$refs.lu3)
				myChart3.setOption(l_u_option3)

				var list4 = res[3]?.list || []
				var l_u_option4 = this.getLeftUpPie('perHoldType', '疫苗接种', list4)
				var myChart4 = echarts.init(this.$refs.lu4)
				myChart4.setOption(l_u_option4)
			})

			this.ajax.get('/admin/index/getCount').then(res => {
				this.totalStatistics = res
			})
			this.ajax.get('/admin/index/getHouseType').then(res => {
				this.getHouseType = res
				var leftDownOption = this.getLeftDownOption(
					this.getHouseType.map(e => e.name),
					this.getHouseType.map(e => e.num),
					colors
				)
				var leftDownChart = echarts.init(this.$refs.ld1)
				leftDownChart.setOption(leftDownOption)
			})

			this.getMapData()
			this.ajax.get('/admin/index/peakValueOfDay').then(res => {
				this.peakValueOfDayData = res
				this.drawPeakValueOfDay()
			})
			this.ajax.get('/admin/index/openDoorMode').then(res => {
				this.openDoorModeData = res
				this.drawOpenDoorMode()
			})
			this.ajax.get('/admin/index/accessControlOnlineRate').then(res => {
				this.accessControlOnlineRate = res
				this.drawAccessControlOnlineRate()
			})
			this.ajax.get('/admin/index/getHouseState').then(res => {
				this.getHouseState = res
			})
			this.ajax.get('/admin/index/getCrAddress').then(res => {
				console.log('getCrAddress', res)

				var centerDown = echarts.init(this.$refs.md1)
				centerDown.setOption(
					this.getLeftDownOption(
						res.map(e => e.name || ''),
						res.map(e => e.num || 0),
						colors
					)
				)
			})
		},
		getCount() {
			this.ajax.get('/admin/index/getCount').then(res => {
				this.totalStatistics = res
			})
		},

		getCrAddress() {
			this.ajax.get('/admin/index/getCrAddress').then(res => {
				this.totalStatistics = res
			})
		},
		getLeftUpPie(seriesName, title, list, colors) {
			var array = new Array()
			for (var i = 0; i < list.length; i++) {
				var a = {
					value: list[i].num,
					name: list[i].name
				}
				array[i] = a
			}
			var l_u_option = {
				title: {
					text: title,
					textStyle: {
						color: this.color,
						fontSize: 14
					}
				},
				tooltip: {
					trigger: 'item',
					position: 'right'
				},
				legend: {
					top: '75%',
					left: 'center',
					textStyle: {
						color: this.color,
						fontSize: 10
					},
					itemWidth: 10,
					itemHeight: 10
				},
				series: [
					{
						name: seriesName,
						type: 'pie',
						left: 'center',
						radius: ['50%', '70%'],
						avoidLabelOverlap: false,
						color: colors,
						label: {
							show: false,
							position: 'center',
							normal: {
								formatter: '',
								textStyle: {
									fontWeight: 'normal',
									fontSize: 12,
									color: this.color
								}
							}
						},
						labelLine: {
							show: false
						},
						data: array
					}
				]
			}
			return l_u_option
		},

		getLeftDownOption(dataxs, dataVs, colors) {
			var array = new Array()
			for (var i = 0; i < dataVs.length; i++) {
				var a = {
					value: dataVs[i],
					itemStyle: {
						color: colors[i]
					}
				}
				array[i] = a
			}
			var option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross'
					}
				},
				xAxis: {
					type: 'category',
					data: dataxs,
					axisLabel: {
						color: this.color
					}
				},
				grid: {
					left: '20px',
					right: '20px',
					bottom: '0px',
					top: '20px',
					containLabel: true
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						color: this.color
					},
					splitLine: {
						show: false
					}
				},
				series: [
					{
						data: array,
						type: 'bar',
						barWidth: '20%',
						showBackground: true
					}
				]
			}
			return option
		},

		getMapData(code = 100000) {
			//请求json
			this.ajax.get(`/admin/index/getMapJson?code=${code}`).then(mapStr => {
				if (!mapStr) {
					return
				}
				//转化json
				this.mapJson = JSON.parse(mapStr)
				//设置当前地图的区域数据
				this.mapData = this.mapJson.features.map(e => {
					return {
						name: e.properties.name,
						adcode: e.properties.adcode,
						level: e.properties.level,
						parent: e.properties.parent?.adcode
					}
				})
				//获取上一级code,name
				if (!this.parentList.map(e => e.parentCode).includes(code)) {
					let obj = {
						parentName: name,
						parentCode: code
					}
					this.parentList.push(obj)
				}
				//判断省市区层级传code
				let form = ''
				let a = this.mapData[0].adcode + ''
				let level = this.mapData[0].level
				if (this.mapData[0].level == 'city') {
					form = 'code=' + a.substr(0, 2)
				} else if (this.mapData[0].level == 'district') {
					form = 'code=' + a.substr(0, 4)
				}
				//获取数据
				this.ajax.get(`/admin/index/getMapList?${form}`).then(res => {
					//获取最大值
					this.max = res.map(e => e.count).sort((a, b) => b - a)[0]
					//用于截断
					var end = level == 'province' ? 2 : level == 'city' ? 4 : 6
					var data = []
					this.mapData.forEach(e => {
						let a = (e.adcode + '').substr(0, end)
						var m = res.filter(e1 => e1.crAddress == a)
						var v = m.length ? m[0].count : 0
						data.push({
							name: e.name,
							value: v
						})
					})
					this.mapValueData = data
					this.drawMap()
				})
			})
		},

		drawMap() {
			let myChart = echarts.init(this.$refs.map)
			//去除监听
			myChart.off('click', this.clickMap)
			myChart.off('georoam', this.georoamMap)
			echarts.registerMap('mapJson', this.mapJson)
			let option = {
				visualMap: {
					bottom: '10px',
					left: 'right',
					right: '60px',
					min: 0,
					max: this.max,
					inRange: {
						color: ['#154C91', '#32C5F6', '#24E3FA', '#13F1FC']
					},
					calculable: true
				},
				series: [
					{
						type: 'map',
						roam: true,
						map: 'mapJson',
						layoutCenter: ['45%', '80%'],
						layoutSize: '90%',
						left: '20px',
						top: 'bottom',
						itemStyle: {
							borderColor: '#2F8AE8'
						},
						label: {
							//地图默认的文本属性
							show: false,
							color: '#2998E8'
						},
						select: {
							//选中的区域文本属性
							label: {
								color: '#2998E8'
							},
							itemStyle: {
								color: '#3066ba'
							}
						},
						emphasis: {
							//高亮的区域文本属性
							itemStyle: {
								areaColor: '#3066ba'
							},
							label: {
								show: true,
								textStyle: {
									color: '#2998E8'
								}
							}
						},
						zoom: 1.4,
						// 文本位置修正
						textFixed: {
							Alaska: [20, -20]
						},
						data: this.mapValueData
					}
				]
				// geo: {
				// 	type: 'map',
				// 	roam: false,
				// 	map: 'mapJson',
				// 	layoutCenter: ['45%', '80%'],
				// 	layoutSize: '90%',
				// 	left: '20px',
				// 	top: 'bottom',
				// 	itemStyle: {
				// 		normal: {
				// 			areaColor: '#013C62',
				// 			shadowColor: '#01273F',
				// 			shadowOffsetX: 0,
				// 			shadowOffsetY: 25
				// 		},
				// 		borderColor: '#2F8AE8'
				// 	},
				// 	label: {
				// 		//地图默认的文本属性
				// 		show: false,
				// 		color: '#2998E8'
				// 	},
				// 	select: {
				// 		//选中的区域文本属性
				// 		label: {
				// 			color: '#2998E8'
				// 		},
				// 		itemStyle: {
				// 			color: '#3066ba'
				// 		}
				// 	},
				// 	emphasis: {
				// 		//高亮的区域文本属性
				// 		itemStyle: {
				// 			areaColor: '#3066ba'
				// 		},
				// 		label: {
				// 			show: true,
				// 			textStyle: {
				// 				color: '#2998E8'
				// 			}
				// 		}
				// 	},
				// 	zoom: 1.4,
				// 	// 文本位置修正
				// 	textFixed: {
				// 		Alaska: [20, -20]
				// 	}
				// }
			}
			//渲染地图
			myChart.setOption(option)
			//监听点击和滚轮放大缩小事件
			myChart.on('click', this.clickMap)
			myChart.on('georoam', this.georoamMap)
		},
		georoamMap(e) {
			console.log(e.zoom)
			//只有缩小才触发退后
			if (this.zoom > e.zoom && this.parentList.length > 1) {
				//利用防抖防止频繁调用返回接口
				clearTimeout(this.backMap)
				this.backMap = setTimeout(() => {
					console.log('重置')
					//去掉当前层级
					let index = this.parentList.length - 1
					this.parentList.splice(index, 1)
					//重新获取上一层级进行跳转
					index = this.parentList.length - 1
					this.getMapData(this.parentList[index].parentCode)
				}, 500)
			}
			this.zoom = e.zoom
		},
		clickMap(e) {
			//获取深入的层级参数调用接口
			let code = this.mapData.find(el => el.name == e.name).adcode
			this.getMapData(code, e.name)
		},
		drawOpenDoorMode() {
			var datays = this.openDoorModeData.map(e => e.name)
			var datas = this.openDoorModeData.map(e => e.num)
			var option = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {},
				grid: {
					bottom: '20px',
					top: '20px',
					left: '20%'
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01],
					axisLine: {
						lineStyle: {
							color: this.color
						}
					}
				},
				yAxis: {
					type: 'category',
					data: datays,
					axisLine: {
						lineStyle: {
							color: this.color
						}
					}
				},
				series: [
					{
						type: 'bar',
						barWidth: '20%',
						data: datas
					}
				]
			}
			var chart = echarts.init(this.$refs.rm)
			chart.setOption(option)
		},
		drawPeakValueOfDay() {
			var dataxs = this.peakValueOfDayData.map(e => {
				return e.name
			})
			var datas = this.peakValueOfDayData.map(e => {
				return e.num
			})
			let option = {
				xAxis: {
					type: 'category',
					data: dataxs,
					axisLine: {
						lineStyle: {
							color: this.color
						}
					}
				},
				grid: {
					bottom: '30px',
					top: '30px'
				},
				yAxis: {
					type: 'value',
					axisLine: {
						lineStyle: {
							color: this.color
						}
					}
				},
				series: [
					{
						data: datas,
						type: 'line',
						smooth: true
					}
				]
			}
			var chart = echarts.init(this.$refs.ru)
			chart.setOption(option)
		},
		drawAccessControlOnlineRate() {
			let option = {
				series: [
					{
						type: 'gauge',
						startAngle: 180,
						endAngle: 0,
						min: 0,
						max: 100,
						splitNumber: 5,
						center: ['50%', '70%'],
						radius: '120%',
						itemStyle: {
							color: '#58D9F9',
							shadowBlur: 10,
							shadowOffsetX: 2,
							shadowOffsetY: 2
						},
						pointer: {
							itemStyle: {
								color: this.color
							}
						},
						axisLine: {
							roundCap: true,
							lineStyle: {
								width: 4,
								color: [
									[0.3, '#FC5967'],
									[0.7, '#52C2FF'],
									[1, '#2BD03B']
								]
							}
						},
						axisTick: {
							lineStyle: {
								width: 2,
								color: this.color
							}
						},
						splitLine: {
							distance: 5,
							length: 15,
							lineStyle: {
								color: this.color,
								width: 4
							}
						},
						axisLabel: {
							distance: 20,
							color: this.color,
							fontSize: 14
						},
						detail: {
							valueAnimation: true,
							formatter: '{value}',
							color: 'auto'
						},
						data: [
							{
								value: this.accessControlOnlineRate
							}
						]
					}
				]
			}
			var chart = echarts.init(this.$refs.rd)
			chart.setOption(option)
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../assets/css/global.scss';
.homeFrame {
	padding: 10px;
	@include home_border_bg(#051c41);
	clip-path: polygon(
		15px 0,
		calc(100% - 15px) 0,
		100% 15px,
		100% calc(100% - 15px),
		calc(100% - 15px) 100%,
		15px 100%,
		0 calc(100% - 15px),
		0 15px
	);
}
.home {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: auto;
	display: flex;
	padding: 10px;
	border-radius: 5px;
	@include home_color(#fff);
	@include main_bg_color(#111111);
}
.left {
	width: 34%;
	// color: #ffffff;
	@include home_color(#fff);
	font-size: 14px;
	margin-right: 5px;
	margin-bottom: 25px;
	.up {
		height: 55%;
		display: grid;
		justify-content: space-evenly;
		grid-template-columns: 50% 50%;
		grid-template-rows: 50% 50%;
		margin-bottom: 5px;
		.ring {
			@extend .homeFrame;
			& div {
				width: 100%;
				height: 100%;
			}
		}
		& .ring:nth-child(1) {
			margin-right: 5px;
			margin-bottom: 5px;
		}
		& .ring:nth-child(2) {
			margin-left: 5px;
			margin-bottom: 5px;
		}
		& .ring:nth-child(3) {
			margin-right: 5px;
			margin-top: 5px;
		}
		& .ring:nth-child(4) {
			margin-left: 5px;
			margin-top: 5px;
		}
	}
	.middle {
		height: 15%;
		// color: #ffffff;
		@include home_color(#fff);
		font-size: 14px;
		@extend .homeFrame;
		margin-bottom: 5px;
		.content {
			display: flex;
			justify-content: space-between;
			margin: 20px;
			.label {
				padding-right: 10px;
				color: #ffffff;
				font-size: 13px;
				font-family: Source Han Sans SC;
				font-weight: bold;
				@include home_color(#fff);
			}
			.value {
				color: #51c2ff;
				font-size: 13px;
			}
		}
	}
	.down {
		height: 30%;
		@extend .homeFrame;
		.category {
			height: calc(100% - 20px);
			width: 100%;
		}
	}
}
.center {
	width: 38%;
	margin-right: 5px;
	margin-bottom: 25px;
	.up {
		height: 13%;
		display: flex;
		justify-content: space-between;
		padding: 20px;
		@extend .homeFrame;
		margin-bottom: 5px;
		.value {
			font-size: 24px;
			color: #52c2ff;
			text-align: center;
			font-family: ZhenyanGB;
			font-weight: 400;
		}
		.label {
			font-size: 14px;
			// color: #ffffff;
			@include home_color(#fff);
			text-align: center;
			font-family: ZhenyanGB;
			font-weight: 400;
		}
	}
	.middle {
		height: 60%;
		@extend .homeFrame;
		margin-bottom: 5px;
		& > div {
			width: 100%;
			height: 90%;
		}
	}
	.down {
		height: 27%;
		@extend .homeFrame;
		.category {
			height: calc(100% - 20px);
			width: 100%;
		}
	}
}
.right {
	width: 30%;
	margin-bottom: 25px;
	.up {
		height: 30%;
		@extend .homeFrame;
		margin-bottom: 5px;
		& > div:nth-child(1) {
			width: 100%;
			height: 20px;
		}
		& > div:nth-child(2) {
			width: 100%;
			height: calc(100% - 20px);
		}
	}
	.middle {
		height: 30%;
		@extend .homeFrame;
		margin-bottom: 5px;
		& > div:nth-child(1) {
			width: 100%;
			height: 20px;
		}
		& > div:nth-child(2) {
			width: 100%;
			height: calc(100% - 20px);
		}
	}
	.down {
		height: 40%;
		@extend .homeFrame;
		margin-bottom: 5px;
		& > div:nth-child(1) {
			width: 100%;
			height: 20px;
		}
		& > div:nth-child(2) {
			width: 100%;
			height: calc(100% - 20px);
		}
	}
}
</style>
