<template>
	<span>{{ carrier }}</span>
</template>
<script>
export default {
	data() {
		return {
			carrier: 0,
			funcNum: null
		}
	},
	props: {
		num: {
			type: Number,
			default: 0
		},
		ms: {
			type: Number,
			default: 40
		},
		s: {
			type: Number,
			default: 2000
		}
	},
	watch: {
		num: function () {
			this.numChange()
		}
	},
	mounted() {
		this.numChange()
	},
	methods: {
		numChange() {
			if (this.ms < Math.abs(this.num)) {
				let add = Math.round(this.num / this.ms)
				let intervalTime = this.s / this.ms
				let _this = this
				this.funcNum = setInterval(() => {
					_this.carrier += add
				}, intervalTime)
				setTimeout(() => {
					clearInterval(_this.funcNum)
				}, this.s)
			} else {
				this.carrier = this.num
			}
		}
	}
}
</script>
<style lang="scss" scoped></style>
